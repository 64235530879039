import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'cleaning',
  formId: 'DK%20Office%20Cleaning',
  title: 'Cleaning',
  subTitle: 'Let your office shine bright like a diamond!',
  icon: '/icons/cleaning.svg',
  image: '/assets/images/sunrise/cleaning_supermercat.png',
  theme: 'sunrise-cleaning',
  sections: [
    {
      sectionId: 'hero',
      label: 'Cleaning',
      title: 'Let your office shine bright like a diamond!',
      description:
        'Well, technically diamonds don’t shine, they reflect light. Our cleaning services will, however, make your office sparkle. From recurring office cleaning and window polishing to waste removal or special cleaning requests, our partners have got you covered.',
      image: '/assets/images/sunrise/cleaning_supermercat.png',
      icon: '/icons/cleaning.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'Keeping your office clean is hotter than ever!',
      description:
        'A safe work environment starts with a clean office. We value our clients’ safety above anything else. ',
      optionsItems: [
        {
          icon: '/assets/images/services/cleaning/service_1.svg',
          title: 'Recurring cleaning',
          description:
            'Make sure your office is spotless and safe! We have something for every wallet and hygiene level.',
        },
        {
          icon: '/assets/images/services/cleaning/service_2.svg',
          title: 'Deep clean',
          description: 'Need to get rid of every single germ at the office? No worries - let’s go deep.',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'A few steps away from a clean office',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/cleaning/journey_1.svg',
          title: 'Office details',
          description: 'To make the perfect proposal we need some info on your office.',
        },
        {
          icon: '/assets/images/services/cleaning/journey_2.svg',
          title: 'Workspace visit',
          description: 'We want to see how you live and work to give you the best offer.',
        },
        {
          icon: '/assets/images/services/cleaning/journey_3.svg',
          title: 'Get proposal',
          description: 'A few days later you’ll get a proposal on the platform - easy.',
        },
        {
          icon: '/assets/images/services/cleaning/journey_4.svg',
          title: 'Accept!',
          description: 'That’s it. Lean back and enjoy a clean and sparkling office',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies choose Good Monday to clean up!',
      description: '',
      image: '/assets/images/services/cleaning/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-award-outline',
          title: 'All vetted suppliers',
          description: 'We’ve quality checked all our suppliers just for you.',
        },
        {
          icon: 'eva eva-message-circle-outline',
          title: 'Easy communication',
          description: 'One place to communicate about the cleaning.',
        },
        {
          icon: 'eva eva-plus-circle-outline',
          title: 'Extra Extra Extra',
          description: 'Big parties, Friday bars? No problem. We’ll clean up.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that in the workplace, people touch as many as 30 objects every 60 seconds?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
